import React from 'react';
import './HeaderSection.css';

const HeaderSection = ({ words, currentWordIndex, handleViewProfessionals, reviews, showInstallButton, handleInstallClick }) => {
  return (
    <div id="header-container" className="portada-reseñas-container">
      <div className="portada-reseñas-titulo">
        <h1>
          Encuentra tu profesional de{' '}
          <span className="portada-reseñas-palabra-cambio">{words[currentWordIndex]}</span>
        </h1>
        <button onClick={handleViewProfessionals} className="portada-reseñas-boton">
          Ver Profesionales
        </button>
        {showInstallButton && (
          <button onClick={handleInstallClick} className="install-button">
            Instalar App
          </button>
        )}
      </div>
      <div className="portada-reseñas-lista">
        {reviews.map((review, index) => (
          <div key={index} className="portada-reseñas-item">
            <img src={review.userPhotoURL} alt={review.userName} className="portada-reseñas-foto" />
            <div className="portada-reseñas-contenido">
              <h3 className="portada-reseñas-usuario">{review.userName}</h3>
              <p className="portada-reseñas-texto">{review.comment}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeaderSection;
