import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import './CreateProfessional.css';

const CreateProfessional = () => {
  const [professionals, setProfessionals] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const fetchProfessionals = async () => {
      const professionalsCollection = collection(db, 'professionals');
      const professionalsSnapshot = await getDocs(professionalsCollection);
      const professionalsList = professionalsSnapshot.docs.map(doc => doc.data());
      setProfessionals(professionalsList);
    };

    fetchProfessionals();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const newProfessional = {
        id: user.uid,
        name,
        email,
      };
      await setDoc(doc(db, 'professionals', user.uid), newProfessional);
      alert('Profesional creado exitosamente');
      setProfessionals([...professionals, newProfessional]);
    } catch (error) {
      alert('Error al crear el profesional: ' + error.message);
    }
  };

  return (
    <div className="create-professional">
      <h1>Crear Profesional</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Nombre"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Correo Electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Crear Profesional</button>
      </form>
      <h2>Profesionales Creados</h2>
      <ul>
        {professionals.map((professional) => (
          <li key={professional.id}>
            {professional.name} ({professional.email})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CreateProfessional;