import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login-charlotmed-business');
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <h2>Charlotmed Business</h2>
        <button onClick={handleLoginRedirect}>
          Iniciar sesión
        </button>
      </div>
    </footer>
  );
};

export default Footer;