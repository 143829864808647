// src/calendario/CustomToolbar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomToolbar = (toolbar) => {
  const navigate = useNavigate();

  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  const goToView = (view) => {
    toolbar.onView(view);
  };

  const goToHome = () => {
    navigate('/home');
  };

  const goToProfile = () => {
    navigate('/professional-profile');
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>Back</button>
        <button type="button" onClick={goToCurrent}>Today</button>
        <button type="button" onClick={goToNext}>Next</button>
      </span>
      <span className="rbc-toolbar-label">{toolbar.label}</span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => goToView('month')}>Month</button>
        <button type="button" onClick={() => goToView('week')}>Week</button>
        <button type="button" onClick={() => goToView('day')}>Day</button>
      </span>
      <span className="rbc-btn-group">
        <button type="button" onClick={toolbar.onBlockingToggle}>
          {toolbar.isBlocking ? 'Cancelar Bloqueo' : 'Bloquear Horario'}
        </button>
      </span>
      <span className="rbc-btn-group">
        <button type="button" onClick={goToHome}>Home</button>
        <button type="button" onClick={goToProfile}>Perfil Profesional</button>
      </span>
    </div>
  );
};

export default CustomToolbar;