// src/ProfessionalReservationForm.js
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { auth, db, addReservation } from './firebase';
import { doc, getDoc, collection, addDoc, onSnapshot } from 'firebase/firestore';
import './ProfessionalReservationForm.css';

const stripePromise = loadStripe('pk_test_51QMfPHLiYDAQqOb6Iev10Ft88a0IjDh4OWQ7bWlJGEgZMNVrP6z4VJbWY9JqJPCE5Z85su2GOpxgMpxM3HSrk7qA00vZU19Zws');

const ProfessionalReservationForm = ({ professionalId }) => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [duration, setDuration] = useState(1);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProfessional = async () => {
      try {
        if (professionalId) {
          const professionalRef = doc(db, "professionals", professionalId);
          const professionalDoc = await getDoc(professionalRef);
          if (professionalDoc.exists()) {
            setSelectedProfessional({ id: professionalId, ...professionalDoc.data() });
          } else {
            setError('Profesional no encontrado.');
          }
        }
      } catch (error) {
        setError('Error al obtener la información del profesional.');
      }
    };
    fetchProfessional();
  }, [professionalId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    const user = auth.currentUser;
    if (!user) {
      setError('Debes iniciar sesión para hacer una reserva.');
      setLoading(false);
      return;
    }

    if (!selectedProfessional) {
      setError('Por favor, selecciona un profesional.');
      setLoading(false);
      return;
    }

    if (!date || !time || duration <= 0) {
      setError('Por favor, completa todos los campos correctamente.');
      setLoading(false);
      return;
    }

    try {
      const professionalData = selectedProfessional;
      const hourlyRate = professionalData.hourlyRate;

      if (!hourlyRate || isNaN(hourlyRate)) {
        setError('El precio por hora no es válido.');
        setLoading(false);
        return;
      }

      const amountToCharge = hourlyRate * duration * 100; // Stripe utiliza centavos

      // Crear la sesión de pago en Firestore
      const docRef = await addDoc(collection(db, 'customers', user.uid, 'checkout_sessions'), {
        mode: 'payment',
        line_items: [{
          price_data: {
            currency: 'usd',
            product_data: {
              name: `Reserva con ${professionalData.name}`,
            },
            unit_amount: hourlyRate * 100, // Stripe espera el monto en centavos
          },
          quantity: duration,
        }],
        success_url: `${window.location.origin}/success`,
        cancel_url: `${window.location.origin}/cancel`,
        client_reference_id: user.uid, // Añadimos esta línea
      });

      // Almacenar sessionId en localStorage
      localStorage.setItem('sessionId', docRef.id);

      // Almacenar datos de la reserva en localStorage
      localStorage.setItem('reservationData', JSON.stringify({
        professionalId,
        date,
        time,
        duration,
        description,
        amount: amountToCharge / 100, // Convertir a dólares
      }));

      // Escuchar cambios en la sesión de pago
      onSnapshot(docRef, async (snap) => {
        if (!snap.exists()) {
          setError('No se pudo encontrar la sesión de pago.');
          setLoading(false);
          return;
        }

        const data = snap.data();
        if (!data) {
          setError('No se pudo obtener la información de la sesión de pago.');
          setLoading(false);
          return;
        }

        const { error, url, payment_status } = data;

        if (error) {
          console.error('Error en la sesión de pago:', error);
          alert(`Error en la sesión de pago: ${error.message}`);
          setLoading(false);
          return;
        }

        if (url) {
          // Redirigir al usuario a Stripe Checkout
          window.location.assign(url);
        }

        if (payment_status === 'paid') {
          console.log('Pago completado, agregando la reserva...');
          // Crear la reserva después de confirmar el pago
          const reservationData = {
            professionalId,
            date,
            time,
            duration,
            description,
            amount: amountToCharge / 100,
            userId: user.uid,
            createdAt: new Date(),
          };

          try {
            await addReservation(user.uid, professionalId, reservationData);
            console.log('Reserva agregada exitosamente a Firestore.');
            alert('¡Reserva realizada con éxito!');
          } catch (error) {
            console.error('Error al agregar la reserva:', error);
            alert('Error al agregar la reserva. Revisa la consola para más detalles.');
          }
          setLoading(false);
        }
      });

    } catch (error) {
      console.error('Error al crear la sesión de pago:', error);
      setError('Error al crear la sesión de pago. Inténtalo de nuevo.');
      setLoading(false);
    }
  };

  const calculateTotal = () => {
    if (selectedProfessional && duration > 0) {
      return selectedProfessional.hourlyRate * duration;
    }
    return 0;
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {error && <p className="error-message">{error}</p>}
        {selectedProfessional && (
          <div className="professional-info">
            <img src={selectedProfessional.photoURL} alt={selectedProfessional.name} className="professional-photo" />
            <p>ID del Profesional: {selectedProfessional.id}</p>
            <p>Nombre: {selectedProfessional.name}</p>
            <p>Precio por hora: ${selectedProfessional.hourlyRate}</p>
          </div>
        )}
        <input 
          type="date" 
          value={date} 
          onChange={(e) => setDate(e.target.value)} 
          required 
        />
        <input 
          type="time" 
          value={time} 
          onChange={(e) => setTime(e.target.value)} 
          required 
        />
        <input 
          type="number" 
          min="1" 
          value={duration} 
          onChange={(e) => setDuration(e.target.value)} 
          required 
          placeholder="Duración en horas"
        />
        <textarea 
          value={description} 
          onChange={(e) => setDescription(e.target.value)} 
          required 
          placeholder="Descripción de la reserva"
        />
        
        <div className="total">
          <p>Total a pagar: ${calculateTotal()}</p>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Procesando...' : 'Reservar'}
        </button>
      </form>
    </div>
  );
};

const WrappedProfessionalReservationForm = (props) => (
  <Elements stripe={stripePromise}>
    <ProfessionalReservationForm {...props} />
  </Elements>
);

export default WrappedProfessionalReservationForm;
