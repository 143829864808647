// src/ProfessionalDetail/DetailReservaAvailability.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProfessionalAvailability } from '../firebase';

const DetailReservaAvailability = () => {
  const { id } = useParams();
  const [availability, setAvailability] = useState({});

  useEffect(() => {
    const fetchAvailability = async () => {
      const professionalAvailability = await getProfessionalAvailability(id);
      setAvailability(professionalAvailability || {});
    };

    fetchAvailability();
  }, [id]);

  return (
    <>
      <h3>Disponibilidad</h3>
      {Object.keys(availability).length === 0 ? (
        <p>No hay disponibilidad disponible.</p>
      ) : (
        <ul className="availability-list">
          {Object.keys(availability).map((date) => (
            <li key={date}>
              <p>{date}</p>
              <ul>
                {Object.keys(availability[date]).map((time) => (
                  <li key={time}>
                    {time}: {availability[date][time]}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default DetailReservaAvailability;