import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, addReservation } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

const Success = () => {
  const [reservationData, setReservationData] = useState(null);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Usuario autenticado:', user.uid);
        setUserId(user.uid);
      } else {
        console.error('Usuario no autenticado.');
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const data = localStorage.getItem('reservationData');
    if (data) {
      const parsedData = JSON.parse(data);
      setReservationData(parsedData);
      console.log('Datos de reserva recuperados:', parsedData);
    }
  }, []);

  useEffect(() => {
    const agregarReserva = async () => {
      if (reservationData && userId) {
        const isProfessional = reservationData.professionalId !== undefined;
        console.log('¿Es profesional?', isProfessional);

        if (isProfessional) {
          try {
            const reservaConUserId = { ...reservationData, userId };
            console.log('Datos de reserva con userId:', reservaConUserId);
            await addReservation(userId, reservationData.professionalId, reservaConUserId);
            console.log('Reserva agregada exitosamente a Firestore.');
            localStorage.removeItem('reservationData');
          } catch (error) {
            console.error('Error al agregar la reserva:', error);
          }
        } else {
          console.error('No se encontró un professionalId en los datos de la reserva.');
        }
      }
    };

    agregarReserva();
  }, [reservationData, userId]);

  if (!reservationData) {
    return <p>Cargando...</p>;
  }

  return (
    <div>
      <h1>Reserva Exitosa</h1>
      <p>ID del Usuario: {userId}</p>
      <p>ID del Profesional: {reservationData.professionalId}</p>
      <p>Fecha: {reservationData.date}</p>
      <p>Hora: {reservationData.time}</p>
      <p>Duración: {reservationData.duration} horas</p>
      <p>Descripción: {reservationData.description}</p>
      <p>Total Pagado: ${reservationData.amount}</p>
    </div>
  );
};

export default Success;
