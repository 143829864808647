// src/professionalprofilescript/Gallery.js
import React, { useState, useEffect } from 'react';
import { uploadGalleryImages, getGalleryImages, deleteGalleryImage } from '../firebase';
import './Gallery.css';

const Gallery = ({ userId }) => {
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryFiles, setGalleryFiles] = useState([]);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      const images = await getGalleryImages(userId);
      setGalleryImages(images);
    };

    fetchGalleryImages();
  }, [userId]);

  const handleGalleryFilesChange = (e) => {
    setGalleryFiles(Array.from(e.target.files));
  };

  const handleGalleryImagesUpload = async () => {
    if (galleryFiles.length === 0) return;
    if (galleryImages.length + galleryFiles.length > 5) {
      alert('No puedes subir más de 5 imágenes en total');
      return;
    }
    try {
      const imageUrls = await uploadGalleryImages(galleryFiles);
      // Eliminar imágenes anteriores
      for (const image of galleryImages) {
        await deleteGalleryImage(image);
      }
      setGalleryImages(imageUrls);
      alert('Imágenes de galería subidas con éxito');
    } catch (error) {
      console.error('Error subiendo las imágenes de galería:', error);
      alert('Error subiendo las imágenes de galería');
    }
  };

  return (
    <div className="muestra-profesional-galeria">
      <h3>Galería</h3>
      <input type="file" multiple onChange={handleGalleryFilesChange} />
      <button onClick={handleGalleryImagesUpload} className="profile-upload-button">Subir Imágenes a la Galería</button>
      <div className="gallery">
        {galleryImages.map((image, index) => (
          <img key={index} src={image} alt={`Gallery ${index}`} className="gallery-image" />
        ))}
      </div>
    </div>
  );
};

export default Gallery;