// professionalprofilescript/ProfileDetailProfesional.js
import React, { useState, useEffect } from 'react';
import { storage, db } from '../firebase'; // Asegúrate de importar correctamente la configuración de Firebase
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import './ProfileDetailProfesional.css';
import './CheckboxStyles.css'; // Importa los estilos de los checkboxes

const ProfileDetailProfesional = ({ profile }) => {
  // Hooks llamados incondicionalmente
  const [details, setDetails] = useState({});
  const [error, setError] = useState(null);
  const [showAllTasks, setShowAllTasks] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [uploading, setUploading] = useState(false); // Estado para manejar la carga

  useEffect(() => {
    if (profile) {
      try {
        // Procesar detalles del perfil
        setDetails(profile.details || {});
      } catch (err) {
        setError('Error al procesar los detalles del perfil.');
      }
    }
  }, [profile]);

  if (!profile) {
    return <p>Perfil no disponible</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  // Asegurarse de que profile es un objeto antes de usar Object.keys
  const profileKeys = Object.keys(profile);
  const allTasks = profile.tasks ? Object.keys(profile.tasks) : [];
  const tasksToShow = showAllTasks ? allTasks : allTasks.slice(0, 3);

  const handleShowMore = () => {
    setShowAllTasks(!showAllTasks);
  };

  const handleProfilePicChange = (e) => {
    if (e.target.files[0]) {
      setNewProfilePic(e.target.files[0]);
    }
  };

  const handleProfilePicUpload = async () => {
    if (newProfilePic) {
      setUploading(true);
      try {
        const storageRef = ref(storage, `profilePics/${profile.id}`);
        await uploadBytes(storageRef, newProfilePic);
        const downloadURL = await getDownloadURL(storageRef);

        // Actualizar la URL de la foto de perfil en Firestore
        const profileDocRef = doc(db, 'professionals', profile.id);
        await updateDoc(profileDocRef, { profilePic: downloadURL });

        // Actualizar el estado del perfil con la nueva URL de la foto
        setDetails((prevDetails) => ({
          ...prevDetails,
          profilePic: downloadURL,
        }));

        alert('Foto de perfil actualizada exitosamente.');
      } catch (err) {
        console.error('Error al subir la foto de perfil:', err);
        alert('Hubo un error al subir la foto de perfil.');
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div className="muestra-profesional-detalles">
      <h2>Detalles del Profesional</h2>
      <p>Nombre: {profile.name}</p>
      <p>Email: {profile.email}</p>
      <p>Fecha de Creación: {profile.creationDate}</p>
      <p>Servicios Completados: {profile.servicesCompleted}</p>
      <p>Calificación Promedio: {profile.averageRating}</p>
      <p>Verificado: {profile.isVerified ? 'Sí' : 'No'}</p>
      <p>Reserva Mínima: {profile.minReservation} minutos</p>
      <p>Clientes Recurrentes: {profile.repeatClients}</p>
      <div className="sobre-mi-informacion">
        <h3>Sobre mí</h3>
        <textarea 
          value={profile.bio} 
          readOnly 
          rows="5" 
          cols="50" 
          aria-label="Descripción del profesional" 
        />
      </div>
      <div className="precio-hora-informacion">
        <h3>Precio por hora</h3>
        <input 
          type="number" 
          value={profile.hourlyRate} 
          readOnly 
          aria-label="Precio por hora" 
        />
      </div>
      <div className="checkbox-informacion">
        <h3>Tareas que puedo realizar</h3>
        <div className="muestra-profesional-tareas">
          {tasksToShow.map((task) => (
            <label key={task} className={profile.tasks[task] ? '' : 'task-not-selected'}>
              <input 
                type="checkbox" 
                name={task} 
                checked={profile.tasks[task]} 
                readOnly 
                aria-label={`Tarea de ${task}`} 
              />
              <span>{task.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</span>
            </label>
          ))}
        </div>
        {allTasks.length > 3 && (
          <button onClick={handleShowMore} className="ver-mas-button">
            {showAllTasks ? 'Ver menos' : 'Ver más'}
          </button>
        )}
      </div>
      <div className="profile-pic-upload">
        <h3>Cambiar foto de perfil</h3>
        <input type="file" onChange={handleProfilePicChange} />
        <button onClick={handleProfilePicUpload} disabled={!newProfilePic || uploading}>
          {uploading ? 'Subiendo...' : 'Subir nueva foto'}
        </button>
      </div>
      <div className="current-profile-pic">
        <h3>Foto de Perfil Actual</h3>
        <img 
          src={details.profilePic || '/defaultProfilePic.jpg'} 
          alt="Foto de perfil" 
          className="profile-pic" 
        />
      </div>
    </div>
  );
};

export default ProfileDetailProfesional;