// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Header from './Header';
import ReservationForm from './ReservationForm';
import Register from './Register';
import Login from './Login';
import MyAccount from './MyAccount';
import Professionals from './Professionals';
import ProfessionalProfile from './ProfessionalProfile';
import ProfessionalDetail from './ProfessionalDetail';
import WrappedProfessionalReservationForm from './ProfessionalReservationForm';
import Reservations from './Reservations';
import ReservationsCalendar from './ReservationsCalendar';
import Home from './Home';
import Success from './Success';
import Cancel from './Cancel';
import CreateProfessional from './CreateProfessional';
import Footer from './Footer';
import LoginCharlotmedBusiness from './LoginCharlotmedBusiness';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import './App.css';

function App() {
  const location = useLocation();
  const [user, loading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isProfessional, setIsProfessional] = useState(false);

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const adminDocRef = doc(db, 'admin', user.uid);
        const adminDoc = await getDoc(adminDocRef);
        if (adminDoc.exists()) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } else {
        setIsAdmin(false);
      }
    };

    const checkProfessional = async () => {
      if (user) {
        const professionalDocRef = doc(db, 'professionals', user.uid);
        const professionalDoc = await getDoc(professionalDocRef);
        if (professionalDoc.exists()) {
          setIsProfessional(true);
        } else {
          setIsProfessional(false);
        }
      } else {
        setIsProfessional(false);
      }
    };

    checkAdmin();
    checkProfessional();
  }, [user]);

  useEffect(() => {
    if (location.pathname === '/reservations-calendar') {
      document.body.classList.add('no-header');
    } else {
      document.body.classList.remove('no-header');
    }
  }, [location]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<ReservationForm />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/professionals" element={<Professionals />} />
        <Route path="/professional-profile" element={<ProfessionalProfile />} />
        <Route path="/professional-detail/:id" element={<ProfessionalDetail />} />
        <Route path="/professional-reservation-form" element={isProfessional ? <WrappedProfessionalReservationForm /> : <Navigate to="/login" />} />
        <Route path="/reservations" element={<Reservations />} />
        <Route path="/reservations-calendar" element={<ReservationsCalendar />} />
        <Route path="/home" element={<Home />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/create-professional" element={isAdmin ? <CreateProfessional /> : <Navigate to="/login" />} />
        <Route path="/professional-profile/:id" element={<ProfessionalProfile />} />
        <Route path="/login-charlotmed-business" element={<LoginCharlotmedBusiness />} />
        <Route path="/" element={<Footer />} />
      </Routes>
      <Footer />
    </div>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;



