import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getProfessionalProfile } from '../firebase';
import DetailReservaSpinner from './DetailReservaSpinner';
import './DetailReservaProfile.css';

const DetailReservaProfile = ({ professionalId }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const professionalProfile = await getProfessionalProfile(professionalId);
        setProfile(professionalProfile || null);
      } catch (error) {
        console.error("Error fetching profile:", error);
        setProfile(null);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [professionalId]);

  if (loading) {
    return <DetailReservaSpinner />;
  }

  if (!profile) {
    return (
      <div>
        <p>No se encontró el perfil profesional.</p>
        <Link to="/professionals">Volver a la lista de profesionales</Link>
      </div>
    );
  }

  return (
    <div className="professional-detail-profile-container">
      <h2>Perfil Profesional</h2>
      <div className="professional-detail-image-container">
        {profile.imageUrl && (
          <img src={profile.imageUrl} alt={profile.name} className="professional-detail-image" />
        )}
      </div>
      <div className="professional-detail-info">
        <p>Nombre: {profile.name || 'Sin nombre'}</p>
        <p>Especialidad: {profile.specialty || 'No especificada'}</p>
        <p>Experiencia: {profile.experience ? `${profile.experience} años` : 'No especificada'}</p>
        <p>
          Valoración Media: {profile.averageRating !== undefined && profile.averageRating !== null
            ? `${profile.averageRating.toFixed(1)} estrellas`
            : 'Sin valoraciones'}
        </p>
        <p>Rango: {profile.rank || 'Sin rango'}</p>
      </div>
      <div className="professional-detail-biography">
        <h3>Biografía</h3>
        <p>{profile.bio || 'Sin biografía disponible.'}</p>
      </div>
      <div className="professional-detail-reservation">
        <Link to={`/professional-reservation/${professionalId}`} className="professional-detail-reservation-link">
          Reservar
        </Link>
      </div>
    </div>
  );
};

export default DetailReservaProfile;
