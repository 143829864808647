// src/professionalprofilescript/Reservations.js
import React, { useState, useEffect, useRef } from 'react';
import { getProfessionalReservations, updateReservationStatus } from '../firebase';
import './Reservations.css';

const Reservations = ({ userId, show, onClose }) => {
  const [reservations, setReservations] = useState([]);
  const panelRef = useRef(null);

  useEffect(() => {
    const fetchReservations = async () => {
      const professionalReservations = await getProfessionalReservations(userId);
      setReservations(professionalReservations);
    };

    fetchReservations();
  }, [userId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, onClose]);

  const handleAccept = async (reservationId) => {
    try {
      await updateReservationStatus(reservationId, 'Aceptada');
      setReservations(prevReservations =>
        prevReservations.map(reservation =>
          reservation.id === reservationId ? { ...reservation, status: 'Aceptada' } : reservation
        )
      );
    } catch (error) {
      console.error('Error aceptando la reserva:', error);
      alert('Error aceptando la reserva');
    }
  };

  const handleCancel = async (reservationId) => {
    try {
      await updateReservationStatus(reservationId, 'Cancelada');
      setReservations(prevReservations =>
        prevReservations.map(reservation =>
          reservation.id === reservationId ? { ...reservation, status: 'Cancelada' } : reservation
        )
      );
    } catch (error) {
      console.error('Error cancelando la reserva:', error);
      alert('Error cancelando la reserva');
    }
  };

  return (
    <div ref={panelRef} className={`notification-panel ${show ? 'show' : ''}`}>
      <button className="close-button" onClick={onClose}>X</button>
      <h3>Reservas Recibidas</h3>
      {reservations.length === 0 ? (
        <p>No hay reservas disponibles.</p>
      ) : (
        <ul className="profile-reservations-list">
          {reservations.map((reservation) => (
            <li key={reservation.id} className="reservation-item">
              <p>Fecha: {reservation.date}</p>
              <p>Hora: {reservation.time}</p>
              <p>Descripción: {reservation.description}</p>
              <p>Estado: {reservation.status}</p>
              {reservation.status === 'Pendiente' && (
                <>
                  <button onClick={() => handleAccept(reservation.id)} className="reservation-accept-button">Aceptar</button>
                  <button onClick={() => handleCancel(reservation.id)} className="reservation-cancel-button">Cancelar</button>
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Reservations;