// src/MyAccount.js
import React, { useState, useEffect } from 'react';
import { auth, db, getUserReservations } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { addOrUpdatePersonalData } from './myaccount/personalData';
import './MyAccount.css';
import Header from './Header';

const MyAccount = () => {
  const [user, loading] = useAuthState(auth);
  const [profilePic, setProfilePic] = useState('');
  const [reservations, setReservations] = useState([]);
  const [file, setFile] = useState(null); // Estado para almacenar el archivo seleccionado
  const [displayName, setDisplayName] = useState(''); // Estado para almacenar el nombre del usuario
  const [personalData, setPersonalData] = useState({
    address: '',
    phoneNumber: ''
  }); // Estado para almacenar los datos personales del usuario

  useEffect(() => {
    const fetchProfilePic = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setProfilePic(userDoc.data().profilePic || '');
            setDisplayName(userDoc.data().displayName || ''); // Obtener el nombre del usuario
            setPersonalData({
              address: userDoc.data().address || '',
              phoneNumber: userDoc.data().phoneNumber || ''
            }); // Obtener los datos personales del usuario
            console.log('Profile Pic:', userDoc.data().profilePic);
          } else {
            console.log('Documento de usuario no existe. Creando uno nuevo.');
            await setDoc(userDocRef, { profilePic: '', displayName: '', address: '', phoneNumber: '' }, { merge: true });
          }
        } catch (error) {
          console.error('Error fetching profile pic:', error);
        }
      }
    };

    const fetchReservations = async () => {
      if (user) {
        try {
          const userReservations = await getUserReservations(user.uid);
          setReservations(userReservations);
          console.log('Reservations:', userReservations);
        } catch (error) {
          console.error('Error fetching reservations:', error);
        }
      }
    };

    fetchProfilePic();
    fetchReservations();
  }, [user]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file && user) {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, `profilePics/${user.uid}`);
        
        // Eliminar la foto de perfil anterior si existe
        if (profilePic) {
          try {
            const oldProfilePicRef = ref(storage, profilePic);
            await deleteObject(oldProfilePicRef);
            console.log('Old profile pic deleted:', profilePic);
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              console.log('Old profile pic does not exist.');
            } else {
              throw error;
            }
          }
        }

        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, { profilePic: downloadURL }, { merge: true }); // Usar setDoc con merge
        setProfilePic(downloadURL);
        console.log('Profile Pic Updated:', downloadURL);
      } catch (error) {
        console.error('Error updating profile pic:', error);
      }
    }
  };

  const handleNameChange = (e) => {
    setDisplayName(e.target.value);
  };

  const handleNameSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, { displayName });
        console.log('Nombre actualizado:', displayName);
      } catch (error) {
        console.error('Error updating name:', error);
      }
    }
  };

  const handlePersonalDataChange = (e) => {
    const { name, value } = e.target;
    setPersonalData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePersonalDataSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      try {
        await addOrUpdatePersonalData(personalData);
        console.log('Datos personales actualizados:', personalData);
      } catch (error) {
        console.error('Error updating personal data:', error);
      }
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="my-account-user">
      <h1>Mi Cuenta</h1>
      <form onSubmit={handleSubmit}>
        <img src={profilePic} alt="Profile" className="my-account-user__profile-pic" />
        <input type="file" onChange={handleFileChange} className="my-account-user__file-input" />
        <button type="submit" className="my-account-user__submit-btn">Actualizar Foto de Perfil</button>
      </form>
      <form onSubmit={handleNameSubmit}>
        <input
          type="text"
          value={displayName}
          onChange={handleNameChange}
          className="my-account-user__name-input"
          placeholder="Nombre"
        />
        <button type="submit" className="my-account-user__submit-btn">Actualizar Nombre</button>
      </form>
      <form onSubmit={handlePersonalDataSubmit}>
        <input
          type="text"
          name="address"
          value={personalData.address}
          onChange={handlePersonalDataChange}
          className="my-account-user__personal-data-input"
          placeholder="Dirección"
        />
        <input
          type="text"
          name="phoneNumber"
          value={personalData.phoneNumber}
          onChange={handlePersonalDataChange}
          className="my-account-user__personal-data-input"
          placeholder="Número de Teléfono"
        />
        <button type="submit" className="my-account-user__submit-btn">Actualizar Datos Personales</button>
      </form>
      <div className="my-account-user__reservations">
        <h2>Reservas</h2>
        {reservations.length === 0 ? (
          <p>No tienes reservas.</p>
        ) : (
          <ul className="my-account-user__reservation-list">
            {reservations.map((reservation) => (
              <li key={reservation.id} className="my-account-user__reservation-item">
                <p>Profesional: {reservation.professionalName || 'Desconocido'}</p>
                <p>Horas Contratadas: {reservation.hours !== 'N/A' ? reservation.hours : 'N/A'}</p>
                <p>Total: {reservation.price !== 'N/A' ? `$${reservation.price}` : 'N/A'}</p>
                <p>Fecha: {reservation.date || 'N/A'}</p>
                <p>Hora: {reservation.time || 'N/A'}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
