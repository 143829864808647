// src/ReviewForm.js
import React, { useState } from 'react';
import { addReview } from './firebase';

const ReviewForm = ({ professionalId }) => {
  const [serviceRating, setServiceRating] = useState(0);
  const [punctualityRating, setPunctualityRating] = useState(0);
  const [friendlinessRating, setFriendlinessRating] = useState(0);
  const [valueRating, setValueRating] = useState(0);
  const [professionalismRating, setProfessionalismRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Calcular la puntuación general
    const totalRating = serviceRating + punctualityRating + friendlinessRating + valueRating + professionalismRating;
    const overallRating = totalRating / 5;

    const review = {
      professionalId,
      serviceRating,
      punctualityRating,
      friendlinessRating,
      valueRating,
      professionalismRating,
      overallRating,
      comment,
    };
    try {
      await addReview(review);
      alert('Reseña enviada con éxito.');
      // Resetear el formulario
      setServiceRating(0);
      setPunctualityRating(0);
      setFriendlinessRating(0);
      setValueRating(0);
      setProfessionalismRating(0);
      setComment('');
    } catch (error) {
      console.error('Error al enviar la reseña:', error);
      alert('Error al enviar la reseña.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Califica los siguientes aspectos del 1 al 5:</h3>
      <div>
        <label>Servicio:</label>
        <select value={serviceRating} onChange={(e) => setServiceRating(parseInt(e.target.value))}>
          <option value={0}>Selecciona una opción</option>
          {[1,2,3,4,5].map((num) => (
            <option key={num} value={num}>{num}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Puntualidad:</label>
        <select value={punctualityRating} onChange={(e) => setPunctualityRating(parseInt(e.target.value))}>
          <option value={0}>Selecciona una opción</option>
          {[1,2,3,4,5].map((num) => (
            <option key={num} value={num}>{num}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Amabilidad:</label>
        <select value={friendlinessRating} onChange={(e) => setFriendlinessRating(parseInt(e.target.value))}>
          <option value={0}>Selecciona una opción</option>
          {[1,2,3,4,5].map((num) => (
            <option key={num} value={num}>{num}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Calidad/Precio:</label>
        <select value={valueRating} onChange={(e) => setValueRating(parseInt(e.target.value))}>
          <option value={0}>Selecciona una opción</option>
          {[1,2,3,4,5].map((num) => (
            <option key={num} value={num}>{num}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Profesionalidad:</label>
        <select value={professionalismRating} onChange={(e) => setProfessionalismRating(parseInt(e.target.value))}>
          <option value={0}>Selecciona una opción</option>
          {[1,2,3,4,5].map((num) => (
            <option key={num} value={num}>{num}</option>
          ))}
        </select>
      </div>
      <div>
        <label>Comentario:</label>
        <textarea value={comment} onChange={(e) => setComment(e.target.value)} rows="4" cols="50" />
      </div>
      <button type="submit">Enviar Reseña</button>
    </form>
  );
};

export default ReviewForm;