import React, { useEffect, useState } from 'react';
import { db, auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './Reservations.css';

const Reservations = () => {
  const [user] = useAuthState(auth);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReservations = async () => {
      if (user) {
        const q = query(collection(db, 'reservations'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const reservationsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setReservations(reservationsData);
        setLoading(false);
      }
    };

    fetchReservations();
  }, [user]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  const upcomingReservations = reservations.filter(reservation => reservation.status === 'upcoming');
  const pastReservations = reservations.filter(reservation => reservation.status === 'past');
  const cancelledReservations = reservations.filter(reservation => reservation.status === 'cancelled');

  return (
    <div className="reservations-container">
      <h1>Historial de Reservas</h1>
      <div className="reservations-section">
        <h2>Próximos Servicios</h2>
        {upcomingReservations.length > 0 ? (
          <ul>
            {upcomingReservations.map(reservation => (
              <li key={reservation.id}>{reservation.serviceName} - {reservation.date}</li>
            ))}
          </ul>
        ) : (
          <p>No hay próximos servicios.</p>
        )}
      </div>
      <div className="reservations-section">
        <h2>Historial de Servicios</h2>
        {pastReservations.length > 0 ? (
          <ul>
            {pastReservations.map(reservation => (
              <li key={reservation.id}>{reservation.serviceName} - {reservation.date}</li>
            ))}
          </ul>
        ) : (
          <p>No hay servicios pasados.</p>
        )}
      </div>
      <div className="reservations-section">
        <h2>Servicios Cancelados</h2>
        {cancelledReservations.length > 0 ? (
          <ul>
            {cancelledReservations.map(reservation => (
              <li key={reservation.id}>{reservation.serviceName} - {reservation.date}</li>
            ))}
          </ul>
        ) : (
          <p>No hay servicios cancelados.</p>
        )}
      </div>
    </div>
  );
};

export default Reservations;