// src/ReservationForm.js
import React, { useState, useEffect } from 'react';
import { getReviews } from './firebase';
import './ReservationForm.css';
import './ReservationForm/Button.css'; // Importa el archivo CSS del botón
import './ReservationForm/Titulohome.css'; // Importa el archivo CSS del título
import './ReservationForm/ServicesSection.css'; // Importa el archivo CSS de los servicios
import { useNavigate } from 'react-router-dom';
import HeaderSection from './ReservationForm/HeaderSection'; // Importa el nuevo componente
import ServicesSection from './ReservationForm/ServicesSection'; // Importa el nuevo componente

const words = ['confianza', 'calidad', 'seguridad', 'experiencia'];

const ReservationForm = () => {
  const [reviews, setReviews] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsData = await getReviews();
        setReviews(reviewsData.slice(0, 5)); // Limita a 5 reseñas
      } catch (error) {
        console.error('Error al obtener las reseñas:', error);
      }
    };

    fetchReviews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1000); // Cambia cada segundo

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Previene que el navegador muestre el banner de instalación por defecto
      e.preventDefault();
      // Guarda el evento para que puedas dispararlo más tarde
      setDeferredPrompt(e);
      // Muestra el botón de instalación
      setShowInstallButton(true);
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', () => {});
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null);
      setShowInstallButton(false);
    }
  };

  const handleViewProfessionals = () => {
    navigate('/professionals');
  };

  const services = [
    {
      title: "Calidad",
      description: "Ofrecemos servicios de alta calidad garantizados por nuestros profesionales.",
      image: "https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/profileImages%2FZ1dFvZknUNMPQBdddNVXbIS0lR92%2Fportada-my-bussines.jpg?alt=media&token=563b70b4-4144-44c4-b5d0-75da9d6e19e2",  // Reemplaza con la ruta real de la imagen
    },
    {
      title: "Experiencia",
      description: "Contamos con años de experiencia en el sector, asegurando un servicio excepcional.",
      image: "https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/profileImages%2FZ1dFvZknUNMPQBdddNVXbIS0lR92%2Fportada-my-bussines.jpg?alt=media&token=563b70b4-4144-44c4-b5d0-75da9d6e19e2",  // Reemplaza con la ruta real de la imagen
    },
    {
      title: "Seguridad",
      description: "Nos aseguramos de que todos nuestros profesionales estén verificados y capacitados.",
      image: "https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/profileImages%2FZ1dFvZknUNMPQBdddNVXbIS0lR92%2Fportada-my-bussines.jpg?alt=media&token=563b70b4-4144-44c4-b5d0-75da9d6e19e2",  // Reemplaza con la ruta real de la imagen
    },
    {
      title: "Confianza",
      description: "La confianza de nuestros clientes es nuestra prioridad, ofreciendo siempre lo mejor.",
      image: "https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/profileImages%2FZ1dFvZknUNMPQBdddNVXbIS0lR92%2Fportada-my-bussines.jpg?alt=media&token=563b70b4-4144-44c4-b5d0-75da9d6e19e2",  // Reemplaza con la ruta real de la imagen
    },
    {
      title: "Innovación",
      description: "Nos mantenemos a la vanguardia con las últimas tecnologías y métodos.",
      image: "https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.firebasestorage.app/o/profileImages%2FZ1dFvZknUNMPQBdddNVXbIS0lR92%2Fportada-my-bussines.jpg?alt=media&token=563b70b4-4144-44c4-b5d0-75da9d6e19e2",  // Reemplaza con la ruta real de la imagen
    },
  ];

  return (
    <div className="reservation-form">
      <HeaderSection
        words={words}
        currentWordIndex={currentWordIndex}
        handleViewProfessionals={handleViewProfessionals}
        reviews={reviews}
        showInstallButton={showInstallButton}
        handleInstallClick={handleInstallClick}
      />
      <ServicesSection services={services} />
      {isIos && !isInStandaloneMode && (
        <div className="ios-install-instructions">
          <p>Para instalar esta aplicación en tu iPhone, toca el icono de compartir y luego "Agregar a la pantalla de inicio".</p>
        </div>
      )}
      {showInstallButton && (
        <button onClick={handleInstallClick}>Instalar App</button>
      )}
    </div>
  );
};

export default ReservationForm;