// src/professionalprofilescript/ConfigPanel.js
import React, { useState, useEffect } from 'react';
import { updateProfessionalProfile, auth } from '../firebase';
import './ConfigPanel.css'; // Importa los estilos

const ConfigPanel = ({ profile, setProfile, handleClose }) => {
  const [bio, setBio] = useState(profile.bio || '');
  const [hourlyRate, setHourlyRate] = useState(profile.hourlyRate || '');
  const [tasks, setTasks] = useState(profile.tasks || {});
  const [bioError, setBioError] = useState('');

  useEffect(() => {
    // Añade la clase 'open' después de que el componente se monte
    const timer = setTimeout(() => {
      document.querySelector('.config-panel').classList.add('open');
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  const handleBioChange = (e) => {
    const newBio = e.target.value;
    setBio(newBio);
    if (newBio.length < 100) {
      setBioError('La biografía debe tener al menos 100 caracteres.');
    } else if (newBio.length > 1000) {
      setBioError('La biografía no puede tener más de 1000 caracteres.');
    } else {
      setBioError('');
    }
  };

  const handleHourlyRateChange = (e) => {
    setHourlyRate(e.target.value);
  };

  const handleTasksChange = (e) => {
    const { name, checked } = e.target;
    setTasks((prevTasks) => ({
      ...prevTasks,
      [name]: checked,
    }));
  };

  const handleBioSubmit = async () => {
    if (bioError) {
      alert('Por favor, corrige los errores en la biografía antes de actualizar el perfil.');
      return;
    }

    try {
      await updateProfessionalProfile(auth.currentUser.uid, { bio, hourlyRate, tasks });
      setProfile((prevProfile) => ({ ...prevProfile, bio, hourlyRate, tasks }));
      alert('Perfil actualizado con éxito');
      handleClose();
    } catch (error) {
      console.error('Error actualizando el perfil:', error);
      alert('Error actualizando el perfil');
    }
  };

  return (
    <div className="config-panel">
      <h3>Editar Perfil</h3>
      <div>
        <h4>Sobre mí</h4>
        <textarea value={bio} onChange={handleBioChange} rows="5" cols="50" />
        {bioError && <p className="error">{bioError}</p>}
      </div>
      <div>
        <h4>Precio por hora</h4>
        <input type="number" value={hourlyRate} onChange={handleHourlyRateChange} />
      </div>
      <div>
        <h4>Tareas que puedo realizar</h4>
        <div className="tasks">
          {Object.keys(tasks).map((task) => (
            <label key={task}>
              <input type="checkbox" name={task} checked={tasks[task]} onChange={handleTasksChange} />
              {task.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
            </label>
          ))}
        </div>
      </div>
      <button onClick={handleBioSubmit}>Actualizar Perfil</button>
    </div>
  );
};

export default ConfigPanel;