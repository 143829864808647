// src/myaccount/personalData.js
import { getFirestore, doc, setDoc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Inicializar Firestore y Auth
const db = getFirestore();
const auth = getAuth();

// Función para agregar o actualizar datos personales del usuario
const addOrUpdatePersonalData = async (userData) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user is currently signed in.');
    }

    const userRef = doc(db, 'users', user.uid);
    await setDoc(userRef, {
      ...userData,
      updatedAt: Timestamp.now() // Añadido para registrar la fecha de actualización
    }, { merge: true }); // Usar merge para actualizar solo los campos proporcionados

    console.log('User personal data added/updated successfully');
  } catch (error) {
    console.error('Error adding/updating personal data: ', error);
  }
};

// Función para agregar una nueva dirección
const addNewAddress = async (address) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No user is currently signed in.');
    }

    const userRef = doc(db, 'users', user.uid);
    await updateDoc(userRef, {
      addresses: arrayUnion({
        address,
        addedAt: Timestamp.now()
      })
    });

    console.log('New address added successfully');
  } catch (error) {
    console.error('Error adding new address: ', error);
  }
};

export { addOrUpdatePersonalData, addNewAddress };