import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getProfessionalReservations, getBlockedTimes, updateBlockedTimes } from './firebase';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import CustomToolbar from './calendario/CustomToolbar'; // Asegúrate de importar el componente de la barra de herramientas personalizada
import CustomDateCellWrapper from './calendario/CustomDateCellWrapper'; // Importa el componente de celda de fecha personalizada
import EventComponent from './calendario/EventComponent'; // Importa el componente de evento
import './calendario/Reservations.css'; // Importa el CSS de las reservas
import './ReservationsCalendar.css';

const localizer = momentLocalizer(moment);

const ReservationsCalendar = () => {
  const [events, setEvents] = useState([]);
  const [isBlocking, setIsBlocking] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // Estado para el día seleccionado
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const professionalId = queryParams.get('professionalId');
  const [currentView, setCurrentView] = useState('day'); // Estado para la vista actual

  useEffect(() => {
    const fetchReservations = async () => {
      if (!professionalId) {
        console.error("professionalId no está definido");
        return;
      }
      const reservations = await getProfessionalReservations(professionalId);
      const blockedTimes = await getBlockedTimes(professionalId);
      const events = [
        ...reservations.map(reservation => {
          const date = new Date(reservation.date);
          if (isNaN(date)) {
            console.error("Fecha de reserva inválida:", reservation.date);
            return null;
          }
          return {
            start: date,
            end: new Date(date.getTime() + (reservation.user.hours || 1) * 60 * 60 * 1000), // Añadir las horas contratadas, usa un valor predeterminado si es necesario
            title: `${reservation.description} - ${reservation.user?.name || 'Desconocido'}`,
            user: reservation.user,
            description: reservation.description,
          };
        }).filter(event => event !== null),
        ...blockedTimes.map(blockedTime => {
          const start = new Date(blockedTime.start);
          const end = new Date(blockedTime.end);
          if (isNaN(start) || isNaN(end)) {
            console.error("Fecha de bloqueo inválida:", blockedTime);
            return null;
          }
          return {
            start,
            end,
            title: 'Bloqueado',
            blocked: true,
          };
        }).filter(event => event !== null)
      ];
      setEvents(events);
    };
    fetchReservations();
  }, [professionalId]);

  const handleSelectSlot = async ({ start, end }) => {
    if (isBlocking) {
      // Verificar y formatear las fechas
      if (isNaN(start) || isNaN(end)) {
        console.error("Fecha inválida seleccionada");
        return;
      }
  
      const newBlockedTime = { start: start.toISOString(), end: end.toISOString() };
      const updatedBlockedTimes = [...events, { ...newBlockedTime, title: 'Bloqueado', blocked: true }];
      setEvents(updatedBlockedTimes);
      await updateBlockedTimes(professionalId, updatedBlockedTimes.filter(event => event.blocked));
      setIsBlocking(false);
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedDate(event.start);
  };

  const handleCloseModal = () => {
    setSelectedDate(null);
  };

  const eventPropGetter = (event) => ({
    style: {
      backgroundColor: event.blocked ? 'red' : 'black',
    },
  });

  const EventComponent = ({ event }) => (
    <div className="small-event" data-tooltip-id={`event-${event.start}`}>
      {event.user && (
        <div className="small-event-content">
          <img src={event.user.photoURL} alt={event.user.name} className="small-event-photo" />
          <span className="small-event-name">{event.user.name}</span>
        </div>
      )}
      <Tooltip id={`event-${event.start}`} place="top" effect="solid">
        <div>
          <p><strong>Descripción:</strong> {event.description}</p>
          <p><strong>Usuario:</strong> {event.user?.name || 'Desconocido'}</p>
          <p><strong>Horas:</strong> {event.user?.hours || 1}</p>
        </div>
      </Tooltip>
    </div>
  );

  // Importa los estilos condicionalmente según la vista actual
  useEffect(() => {
    if (currentView === 'month') {
      import('./calendario/MonthView.css');
    } else if (currentView === 'day') {
      import('./calendario/DayView.css');
    }
  }, [currentView]);

  // Filtra los eventos para el día seleccionado
  const selectedDateEvents = events.filter(event => {
    return selectedDate && event.start.toDateString() === selectedDate.toDateString();
  });

  return (
    <div className="reservations-calendar" style={{ height: '100vh', width: '100vw' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        style={{ height: '100%', width: '100%' }} // Permite que el calendario ocupe el espacio restante
        eventPropGetter={eventPropGetter}
        views={['month', 'day']} // Solo permitir vistas de mes y día
        defaultView="day" // Vista predeterminada
        onView={(view) => setCurrentView(view)} // Actualiza la vista actual
        components={{
          event: EventComponent,
          toolbar: (props) => <CustomToolbar {...props} onBlockingToggle={() => setIsBlocking(!isBlocking)} isBlocking={isBlocking} />
        }}
        longPressThreshold={10} // Reduce el tiempo de espera para el evento de pulsación larga
        popup={false} // Desactiva el popup de eventos adicionales
        showMultiDayTimes={true} // Mostrar eventos de varios días
        step={30} // Intervalo de tiempo en minutos
        timeslots={2} // Número de intervalos de tiempo por hora
        maxRows={Infinity} // Permite que el calendario se expanda para mostrar todas las reservas
      />
      {selectedDate && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Reservas para {selectedDate.toDateString()}</h2>
            {selectedDateEvents.map(event => (
              <div key={event.title} className="event">
                <strong>{event.title}</strong>
                <p>{event.description}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReservationsCalendar;