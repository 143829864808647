// src/ProfessionalDetail/DetailReservaSpinner.js
import React from 'react';

const DetailReservaSpinner = () => (
  <div className="spinner-container">
    <div className="spinner"></div>
    <p>Cargando...</p>
  </div>
);

export default DetailReservaSpinner;