// src/ServicesSection.js
import React from 'react';
import './ServicesSection.css';

const ServicesSection = ({ services }) => {
  return (
    <div id="services-container" className="container">
      <h1 className="services-title">¿Cómo funciona?</h1>
      <div className="services-cards">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div
              className="service-image"
              style={{ backgroundImage: `url(${service.image})` }}
            ></div>
            <h2>{service.title}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;