// src/professionalprofilescript/Reviews.js
import React, { useState } from 'react';
import './Reviews.css';

const Reviews = ({ reviews }) => {
  const [visibleReviews, setVisibleReviews] = useState(3);

  const handleShowMore = () => {
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 3);
  };

  return (
    <div className="muestra-profesional-resenas">
      {reviews.slice(0, visibleReviews).map((review, index) => (
        <div key={index} className="review-card">
          <p>Servicio: {review.serviceRating} estrellas</p>
          <p>Puntualidad: {review.punctualityRating} estrellas</p>
          <p>Amabilidad: {review.friendlinessRating} estrellas</p>
          <p>Calidad / Precio: {review.valueRating} estrellas</p>
          <p>Profesionalidad: {review.professionalismRating} estrellas</p>
          <p>Comentario: {review.comment}</p>
        </div>
      ))}
      {visibleReviews < reviews.length && (
        <button onClick={handleShowMore} className="show-more-button">Ver más</button>
      )}
    </div>
  );
};

export default Reviews;