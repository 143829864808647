import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Cancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Recuperar el professionalId desde localStorage
    const professionalId = localStorage.getItem('professionalId');
    if (professionalId) {
      // Redirigir al perfil del profesional
      navigate(`/professional-detail/${professionalId}`);
    } else {
      // Si no se encuentra el professionalId, redirigir a una página de error o a la página principal
      navigate('/home');
    }
  }, [navigate]);

  return null;
};

export default Cancel;