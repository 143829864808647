import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faStar as faStarEmpty } from '@fortawesome/free-solid-svg-icons';
import './StarRating.css';

const StarRating = ({ rating }) => {
  // Asegúrate de que el rating sea un número válido y positivo
  const validRating = Math.max(0, Math.min(5, parseFloat(rating) || 0));

  const fullStars = Math.floor(validRating);
  const halfStar = validRating % 1 !== 0;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <div className="star-rating">
      {[...Array(fullStars)].map((_, index) => (
        <FontAwesomeIcon key={index} icon={faStar} className="star full-star" />
      ))}
      {halfStar && <FontAwesomeIcon icon={faStarHalfAlt} className="star half-star" />}
      {[...Array(emptyStars)].map((_, index) => (
        <FontAwesomeIcon key={index} icon={faStarEmpty} className="star empty-star" />
      ))}
    </div>
  );
};

export default StarRating;