// src/professionalprofilescript/professionalProfileFunctions.js
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const uploadProfileImage = async (userId, file) => {
  if (!userId || !file) {
    throw new Error('User ID and file are required');
  }

  const storage = getStorage();
  const storageRef = ref(storage, `profilePictures/${userId}`);

  // Subir la imagen a Firebase Storage
  await uploadBytes(storageRef, file);

  // Obtener la URL de descarga de la imagen
  const imageUrl = await getDownloadURL(storageRef);

  // Actualizar la URL de la imagen en el perfil del profesional en Firestore
  const professionalDocRef = doc(db, 'professionals', userId);
  await updateDoc(professionalDocRef, { imageUrl });

  return imageUrl;
};