// src/professionalprofilescript/Comments.js
import React from 'react';
import './Comments.css';

const Comments = ({ repeatClients }) => {
  return (
    <div className="muestra-profesional-comentarios">
      <label>Número de clientes que han repetido:</label>
      <p>{repeatClients}</p>
    </div>
  );
};

export default Comments;