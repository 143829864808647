// src/ProfessionalDetail.js
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DetailReservaProfile from './ProfessionalDetail/DetailReservaProfile';
import DetailReservaAvailability from './ProfessionalDetail/DetailReservaAvailability';
import DetailReservaReviews from './ProfessionalDetail/DetailReservaReviews';
import DetailReservaGallery from './ProfessionalDetail/DetailReservaGallery';
import ReviewForm from './ReviewForm';
import ProfessionalReservationForm from './ProfessionalReservationForm';
import './ProfessionalDetail.css';

const ProfessionalDetail = () => {
  const { id: professionalId } = useParams();

  return (
    <div className="professional-detail-container">
      <div className="professional-detail-profile">
        <DetailReservaProfile professionalId={professionalId} />
      </div>
      <div className="professional-detail-reservation-form">
        <ProfessionalReservationForm professionalId={professionalId} />
      </div>
      <div className="professional-detail-availability">
        <DetailReservaAvailability professionalId={professionalId} />
      </div>
      <div className="professional-detail-reviews">
        <DetailReservaReviews professionalId={professionalId} />
      </div>
      <div className="professional-detail-gallery">
        <DetailReservaGallery professionalId={professionalId} />
      </div>
      <div className="review-form">
        <ReviewForm professionalId={professionalId} />
      </div>
    </div>
  );
};

export default ProfessionalDetail;