import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginAdmin } from './firebase';
import './Login.css';

const LoginCharlotmedBusiness = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const user = await loginAdmin(email, password);
      alert('Administrador logueado con éxito');
      navigate('/create-professional');
    } catch (error) {
      alert('Error al iniciar sesión como administrador: ' + error.message);
    }
  };

  return (
    <div className="login-container">
      <h2>Iniciar sesión en Charlotmed Business</h2>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Correo Electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Iniciar sesión</button>
      </form>
    </div>
  );
};

export default LoginCharlotmedBusiness;