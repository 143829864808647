// src/ProfessionalDetail/DetailReservaReviews.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getReviews, subscribeToReviews } from '../firebase';

const DetailReservaReviews = () => {
  const { id } = useParams();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const initialReviews = await getReviews(id);
      setReviews(initialReviews || []);
    };

    fetchReviews();

    const unsubscribe = subscribeToReviews(id, (newReviews) => {
      setReviews(newReviews || []);
    });

    return () => unsubscribe();
  }, [id]);

  return (
    <>
      <h3>Reseñas</h3>
      {reviews.length === 0 ? (
        <p>No hay reseñas disponibles.</p>
      ) : (
        <ul className="professional-detail-reviews-list">
          {reviews.map((review) => (
            <li key={review.id} className="professional-detail-review-item">
              <p>Puntuación General: {typeof review.overallRating === 'number' ? review.overallRating.toFixed(1) : 'N/A'} estrellas</p>
              <p>Servicio: {review.serviceRating} estrellas</p>
              <p>Puntualidad: {review.punctualityRating} estrellas</p>
              <p>Amabilidad: {review.friendlinessRating} estrellas</p>
              <p>Calidad / Precio: {review.valueRating} estrellas</p>
              <p>Profesionalidad: {review.professionalismRating} estrellas</p>
              <p>Comentario: {review.comment}</p>
              <p>Fecha: {new Date(review.timestamp.toDate()).toLocaleDateString()}</p>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default DetailReservaReviews;