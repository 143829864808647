// src/ProfessionalDetail/DetailReservaGallery.js
import React, { useEffect, useState } from 'react';
import { getGalleryImages } from '../firebase';
import './DetailReservaGallery.css';

const DetailReservaGallery = ({ professionalId }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      console.log('Fetching gallery for professionalId:', professionalId); // Verifica el ID
      try {
        const galleryImages = await getGalleryImages(professionalId);
        setImages(galleryImages);
      } catch (error) {
        console.error('Error al obtener las imágenes de la galería:', error);
      }
    };

    fetchGalleryImages();
  }, [professionalId]);

  return (
    <div className="detail-reserva-gallery">
      <h3>Galería de Trabajos</h3>
      <div className="gallery-images">
        {images.length > 0 ? (
          images.map((image, index) => (
            <img key={index} src={image} alt={`Trabajo ${index + 1}`} className="gallery-image" />
          ))
        ) : (
          <p>No hay imágenes disponibles.</p>
        )}
      </div>
    </div>
  );
};

export default DetailReservaGallery;
