// professionalprofilescript/ProfilePicture.js
import React, { useRef, useState } from 'react';
import { FaPencilAlt, FaBell } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { usePopper } from 'react-popper';
import { uploadProfileImage } from './professionalProfileFunctions';
import { auth } from '../firebase';
import Reservations from './Reservations'; // Asegúrate de que la ruta sea correcta
import './ProfilePicture.css';

const ProfilePicture = ({ profile, setProfile, setShowConfigPanel, userName, profileCreationDate, servicesCompleted, averageRating, isVerified }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [tempImage, setTempImage] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const fileInputRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });
  const navigate = useNavigate();

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const imageUrl = await uploadProfileImage(auth.currentUser.uid, file);
      setProfile((prevProfile) => ({ ...prevProfile, imageUrl }));
      setShowMenu(false);
      window.location.reload(); // Recargar la página
    } catch (error) {
      console.error('Error subiendo la imagen:', error);
      alert('Error subiendo la imagen');
    }
  };

  return (
    <div className="muestra-profesional-resumen">
      <h2>Perfil Profesional</h2>
      <div className="profile-picture-container" ref={setReferenceElement} onClick={() => setShowMenu(!showMenu)}>
        <img
          src={profile.imageUrl || tempImage || 'https://firebasestorage.googleapis.com/v0/b/app-cleaning-9c173.appspot.com/o/Portadas%2F00018-1236161885.png?alt=media&token=b2f0d592-9049-4ac6-a4c8-4448ed5aef83'}
          alt="Profile"
          className="profile-picture"
        />
        <FaPencilAlt className="edit-icon" />
        <FaBell className="profile-notification-bell" onClick={() => setShowNotifications(!showNotifications)} />
      </div>
      <p>{userName} en Charlotmed</p>
      <p>Fecha de creación del perfil: {new Date(profileCreationDate).toLocaleDateString()}</p>
      <p>Servicios realizados: {servicesCompleted}</p>
      <p>Valoración media: {averageRating}</p>
      <p>Estado del perfil: {isVerified ? 'Verificado' : 'No verificado'}</p>
      {showMenu && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="dropdown-menu">
          <button onClick={() => fileInputRef.current.click()} className="profile-upload-button">Cambiar foto de perfil</button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </div>
      )}
      <button
        onClick={() => navigate(`/reservations-calendar?professionalId=${auth.currentUser.uid}`)}
        className="profile-upload-button"
      >
        Ver Calendario de Reservas
      </button>
      <Reservations userId={auth.currentUser.uid} show={showNotifications} onClose={() => setShowNotifications(false)} />
    </div>
  );
};

export default ProfilePicture;